import React, { Component } from 'react';
import Video from 'common/video'
import Dot from './components/dot';
import './hero.css';
import NavBar from "./components/navbar"
import PlaylistNav from "./components/playlistNav"

import HOYCalendar from "assets/101010-house-of-yes-calendar.mp4";
import HOYParty from "assets/101010-house-of-yes-dance-party.mp4";
import HOYCompetition from "assets/101010-house-of-yes-drag-competition.mp4";
import HOYParadise from "assets/101010-house-of-yes-paradise.mp4";
import HOYUnicorn from "assets/101010-house-of-yes-unicorn.mp4";
import HaywireBali from "assets/10102020-haywire-weekend-bali.mp4";
import HaywireHydrate from "assets/10102020-haywire-weekend-hydrate.mp4";
import HaywireSki from "assets/10102020-haywire-weekend-ski.mp4";
import HaywireTeaser from "assets/10102020-haywire-weekend-teaser.mp4";
import HaywireTV from "assets/10102020-haywire-weekend-tv.mp4";

import HOYVideo1 from "assets/video-1.mp4";
import HaywireVideo2 from "assets/video-2.mp4";
import HaywireVideo3 from "assets/video-3.mp4";
import HaywireVideo4 from "assets/video-4.mp4";

const HOUSE_OF_YES = [
  {
    id: 0,
    title: "Dance Party",
    src: HOYParty,
    duration: 48,
    durationPretty: "00:48"
  },
  {
    id: 1,
    title: "Salsa & Latin Night",
    src: HOYVideo1,
    duration: 60,
    durationPretty: "1:00"
  },
  {
    id: 2,
    title: "Drag Competition",
    src: HOYCompetition,
    duration: 15,
    durationPretty: "00:15"
  },
  {
    id: 3,
    title: "Paradise",
    src: HOYParadise,
    duration: 60,
    durationPretty: "1:00"
  },
  {
    id: 4,
    title: "Unicorn",
    src: HOYUnicorn,
    duration: 59,
    durationPretty: "00:59"
  }
];

const PLAYLISTS = [
  {
    id: 0,
    title: "House Of Yes",
    videos: HOUSE_OF_YES,
    duration: "4:04",
    durationPretty: "4:04"
  }
];

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlyPlayingSrc: "",
      currentlyPlayingVideoId: 0,
      currentlySelectedPlaylistIndex: 0,
      navVisibility: false
    }
  }

  componentDidMount() {
    let {
      currentlySelectedPlaylistIndex,
      currentlyPlayingVideoId
    } = this.state;
    this.setState({
      currentlyPlayingSrc: PLAYLISTS[currentlySelectedPlaylistIndex].videos[currentlyPlayingVideoId].src
    });
  }

  handleChangeVideo(id) {
    let { currentlySelectedPlaylistIndex } = this.state;
    let selected = PLAYLISTS[currentlySelectedPlaylistIndex].videos.filter((video) => video.id == id);
    this.setState({
      currentlyPlayingSrc: selected[0].src,
      currentlyPlayingVideoId: id
    });
  }

  handleChangePlaylist(index) {
    this.setState({
      currentlySelectedPlaylistIndex: index,
      currentlyPlayingVideoId: 0,
      currentlyPlayingSrc: PLAYLISTS[index].videos[0].src
    })
  }

  setNavVisibility() {
    this.setState({
      navVisibility: !this.state.navVisibility
    })
  }

  getCurrentlyPlaying() {
    let { currentlySelectedPlaylistIndex, currentlyPlayingVideoId } = this.state;
    let newIndex;

    if (currentlyPlayingVideoId === PLAYLISTS[currentlySelectedPlaylistIndex].videos.length - 1) {
      newIndex = 0
    } else {
      newIndex = currentlyPlayingVideoId + 1;
    }

    this.handleChangeVideo(newIndex);
  }


  render() {
    let {
      currentlyPlayingSrc,
      currentlyPlayingVideoId,
      currentlySelectedPlaylistIndex
    } = this.state

    let {
      subscribeEmail
    } = this.props;

    if (currentlyPlayingSrc === null) return null;
    return (
      <div className="expt-home-hero-container">
        <Video
          onEnded={this.getCurrentlyPlaying.bind(this)}
          src={currentlyPlayingSrc}/>
        <NavBar setNavVisibility={this.setNavVisibility.bind(this)} />
        <PlaylistNav
          playlists={PLAYLISTS}
          setNavVisibility={this.setNavVisibility.bind(this)}
          currentlyPlayingVideoId={currentlyPlayingVideoId}
          selectedPlaylist={PLAYLISTS[currentlySelectedPlaylistIndex]}
          onChangeVideo={this.handleChangeVideo.bind(this)}
          onChangePlaylist={this.handleChangePlaylist.bind(this)}
        />
      </div>
    )
  }
}
