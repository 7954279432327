/*import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccordionPanel from 'components/AccordionPanel';

import './Accordion.css';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    let className = ["accordion-container"];

    className = (this.props.className !== null ? className.concat([this.props.className]) : className);

    return (
      <div className={className.join(" ")}>
        {
          this.props.panels.map((panel, index)=>{
            let {label, content} = panel;
            return (
              <AccordionPanel key={index} label={label} content={content} />
            )
          })
        }
      </div>
    );
  }
}

export default Accordion;*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccordionPanel from 'components/AccordionPanel';
import { Collapse } from 'antd';
import './Accordion.css';

const { Panel } = Collapse;




class Accordion extends Component {
  static defaultProps = {
      type: 'light-gray',
      panelClassName: ""
  }

  setPanelClassName() {
    let { panelClassName, type, background } = this.props;
    let classNames = ["accordion-panel"];

    if (background==="transparent") {
      classNames.push("restage-background-transparent")
    }
    if (panelClassName) {
      classNames.push(panelClassName)
    }

    if (type === 'light-gray') {
      classNames.push("body-copy-bold-light-gray");
    } else if (type === 'brand') {
      classNames.push("body-copy-bold-brand");
    } else if (type === 'purple') {
      classNames.push("body-copy-bold-purple");
    } else {
      classNames.push("body-copy-bold-light-gray");
    }

    return classNames.join(" ");
  }

  render() {
    let className = ["accordion-container"];

    className = (this.props.className !== null ? className.concat([this.props.className]) : className);

    return (
      <Collapse className={className.join(" ")}>
        {
          this.props.panels.map((panel, index)=>{
            let {label, content} = panel;
            return (
              <Panel className={this.setPanelClassName()} key={index} header={label}>
                {content()}
              </Panel>
            )
          })
        }
      </Collapse>
    );
  }
}

export default Accordion;
