import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from 'common/listitem.component'

import './list.css';

class List extends Component {
  static defaultProps = {
    emptyMessage: "",
    expand: false,
    itemClassName: "",
    justification: 'horizontal'
  }

  setListUI() {
    let { items, expand, justification, itemClassName, type } = this.props;

    console.log("tye type", type)

    if (!items) {
      return (
        <div>
          <ListItem item={this.props.emptyMessage} />
        </div>
      )
    }
    let className="flex-row flex-wrap d-flex";

    if (justification === 'vertical') {
      className=""
    }

    if (items.length > 0) {
      return (
        <div className={className}>
          {
            items.map((item, index)=>{
              return (
                <ListItem type={type} itemClassName={itemClassName} expand={expand} key={index} item={item} />
              )
            })
          }
        </div>
      )
    } else {
      return (
        <div className="body-copy-tertiary-small">{this.props.emptyMessage}</div>
      )
    }

  }

  render() {
    return this.setListUI();
  }
}

export default List;
