import React, { Component } from 'react';
import { generateDateFormat, generateFullDateFormat, generateTimeFormat } from 'common/helpers/date';
import { generateAddressFormat, generateLocationFormat } from 'common/helpers/address';
import NoEventLogo from 'assets/noLogo.png';
import './eventsidepanel.css';

class EventSidePanel extends Component {
  getDate() {
    let { startdate, enddate } = this.props;
    startdate = new Date(startdate);
    enddate = new Date(enddate);
    if (startdate.getYear() !== enddate.getYear()) {
      return (<div className="body-copy-tertiary">{generateFullDateFormat(startdate)} - {generateFullDateFormat(enddate)}</div>);
    }
    return (<div className="body-copy-tertiary">{generateDateFormat(startdate)} - {generateDateFormat(enddate)}</div>);
  }

  getAddressFormat(street_number, route) {
    if (street_number === null && route === null) return;
    return <div className="body-copy-tertiary">{generateAddressFormat(street_number, route)}</div>
  }

  render() {
    let { website, tagline, name, startdate, enddate, organization, logos, location } = this.props;
    let { administrative_area_level_1, country, locality, postal_code, route, street_number} = location;

    let logoElement = <img alt="" style={{height: '175px'}} src={NoEventLogo} />

    if (logos.length > 0) {
      logoElement = <img alt="Event or company logo" style={{width: '80%' }} src={logos[0].src} />
    }

    return (
      <div className="eventsidepanel col-md-4 col-12">
        <div style={{paddingBottom: '60px', position: 'relative'}} className="d-flex flex-column eventsidepanel-container">
          {this.props.children}
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex align-items-center justify-content-center" style={{marginBottom: '25px'}}>
              {logoElement}
            </div>
            <div style={{marginBottom: '25px', textAlign: 'center'}} className="header-2-mid-purple">{name}</div>
            <div style={{textAlign: 'center', marginBottom: '50px', wordBreak: "break-word"}} className="body-copy-tertiary">{tagline}</div>
          </div>
          <div className="eventsidepanel-wrapper d-flex flex-column align-items-start">
            <div>
              <div className="body-copy-bold-tertiary">Event Organizer</div>
              <div className="body-copy-tertiary">{organization.name}</div>
            </div>
            <div>
              <div className="body-copy-bold-tertiary">Venue</div>
              {/*<div className="body-copy-tertiary">{venuename}</div>
              <div className="body-copy-tertiary">{venueaddress}</div>*/}
              <div className="body-copy-tertiary">{location.name}</div>
              {this.getAddressFormat(street_number, route)}
              <div className="body-copy-tertiary">{generateLocationFormat(locality, administrative_area_level_1, postal_code, country)}</div>
            </div>
            <div>
              <div className="body-copy-bold-tertiary">Date</div>
              {this.getDate()}
              <div className="body-copy-tertiary">{generateTimeFormat(new Date(startdate))} - {generateTimeFormat(new Date(enddate))}</div>
            </div>
            <div>
              <div className="body-copy-bold-tertiary">Website</div>
              <div style={{wordBreak: "break-word"}}className="body-copy-tertiary">
                <a className="body-copy-tertiary-link" href={website} target="_blank" rel="noopener noreferrer">{website}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventSidePanel;
