/*import React, { Component } from 'react'
import Accordion from 'components/Accordion'
import './packages.css'
export default class Packages extends Component {
  getPanels() {
    const { packages } = this.props
    const buffer =
      let content = () => {
        return (
          <div className="restage-background-transparent">
            Blah
          </div>
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row">
            <div>
              <div className='body-copy-bold-brand'></div>
              <div className="body-copy-tertiary"></div>
            </div>
          </div>
        ),
        content
      }

      return [
        panel,
        panel,
        panel
      ]
  }
  render() {
    const { className, label } = this.props;
    return (
      <div className={className}>
        <div className="restage-font-24 restage-font-333333">{label}</div>
        <Accordion background="transparent" panels={this.getPanels()} />
      </div>
    )
  }
}*/

import React, { Component } from 'react'
import Accordion from 'components/Accordion'
import Button from 'components/new/Button';
import CustomizePackageModal from './CustomizePackageModal';
import List from 'common/list.component';
import './bulletedpackages.css'

export default class BulletedPackages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPackage: null,
      isCustomizePackageModalVisible: false
    }
  }

  getPanels() {
    const { packages } = this.props
    return packages.map((option, index) => {
      const { name, price, items } = option
      const list = items.map((item) => item.description)
      let content = () => {
        return (
          <List key={`package - ${index}`} expand={true} justification="vertical" items={list} />
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row justify-content-between">
            <div>
              <div className='body-copy-bold-brand'>{name}</div>
              <div className="body-copy-tertiary">{price}</div>
            </div>
            <div>
              <Button label="LEARN MORE" type="quarternary" onClick={this.onClickOpenModal.bind(this, option)} stopPropagation={true} />
            </div>
          </div>
        ),
        content
      }
      return panel
    });
  }

  onClickOpenModal(option) {
    console.log("onClickOpenModal", option, "yerrr")
    this.setState({
      selectedPackage: option,
      isCustomizePackageModalVisible: true
    })
  }

  onClickCloseModal() {
    this.setState({
      isCustomizePackageModalVisible: false
    })
  }

  render() {
    const { className, label } = this.props;
    const { selectedPackage, isCustomizePackageModalVisible } = this.state;
    return (
      <div className={className}>
        <div className="restage-font-24 restage-font-333333">{label}</div>
        <Accordion background="transparent" panels={this.getPanels()} />
        <CustomizePackageModal
          isModalVisible={isCustomizePackageModalVisible}
          selectedPackage={selectedPackage}
          onClickClose={this.onClickCloseModal.bind(this)} />
      </div>
    )
  }
}
