import React, { Component } from 'react';
import {
  Player,
} from 'video-react';
import HLSSource from './hlssource';
import PlayButtonIcon from 'assets/branded-play-button.png';
import './video.css';

export default class Video extends Component {
  constructor() {
    super();
    this.state = {
      src: null,
      playing: false
    }
  }

  componentDidUpdate(prevProps) {
    let { src } = this.props;
    if (src && prevProps.src !== src) {
      this.setState({
        src: null
      }, ()=> {
        this.setState({
          src
        })
      })
    }
  }

  onLoadedData() {
    let autoPlay = true;
    if (autoPlay) {
      this.player.play()
    }
  }

  getPlayOverlay() {
    if (this.state.playing) return null;

    return (
      <div className="d-flex align-items-center justify-content-center expt-play-wrapper">
        <img className="expt-play" onClick={this.onClickPlay.bind(this)} src={PlayButtonIcon} />
      </div>
    )
  }

  onPlaying(event) {
    this.setState({
      playing: true
    })
  }

  onPause(event) {
    this.setState({
      playing: false
    })
  }

  onClickPlay() {
    this.player.play()
  }

  render() {
    let { src } = this.state;
    let { onEnded } = this.props;
    if (src === null) return null;
    return (
      <div style={{position: 'relative'}}>
        {this.getPlayOverlay()}
        <Player
          muted={false}
          onPlaying={this.onPlaying.bind(this)}
          onPause={this.onPause.bind(this)}
          onEnded={onEnded.bind(this)}
          onLoadedData={this.onLoadedData.bind(this)}
          playsInline={false}
          ref={player => {
              this.player = player;
            }}
          autoPlay={true}>
            <source src={src} type="video/mp4" />
            {/*}<HLSSource
              autoPlay={true}
              isVideoChild
              src={src}
            />*/}

        </Player>
      </div>
    )
  }
}
