import React, { Component } from 'react';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import Wrapper from 'components/Wrapper';
import Button from 'components/new/Button';
import NotFoundImg from 'assets/notfound.svg';
import { Routes } from 'constants/Routes';
import './notfound.css';

class NotFound extends Component {
  goToPath(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <Wrapper className="container d-flex flex-column flex-fill">
        <div className="d-flex flex-column">
          <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px'}} header={"Not found"} />
          <Divider  style={{marginBottom: '25px'}}/>
        </div>
        <div style={{marginBottom: '30px'}} className="d-flex align-items-center justify-content-center flex-fill">
          <img src={NotFoundImg} style={{width: '100%', maxWidth: '300px'}}/>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            size="md"
            type="primary"
            label={"Back To Home"}
            onClick={this.goToPath.bind(this, Routes.LANDING)} />
        </div>
      </Wrapper>
    );
  }
}

export default NotFound;
