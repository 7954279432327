import React, { Component } from 'react';
import { Routes } from 'constants/Routes';
import { UserTypes } from 'constants/UserTypes';
import { withRouter, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import firebase from '../firebase/Firebase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ProtectedRoute extends Component {
  static defaultProps = {
    allowedRoles: []
  }
  render() {
    let isAllowed = true;
    let redirect =  Routes.LOG_IN;

    if (this.props.allowedRoles.length > 0  && this.props.user !== null) {
      if (!this.props.allowedRoles.includes(this.props.user.user_type_id)) {
        isAllowed = false;
      }
    }

    if (firebase.isUserAuthenticated() && isAllowed) {
      let Component = this.props.component;
      let props = {...this.props };
      delete props.component;
        return <Route exact path={this.props.path} render={(routeProps)=>{
          return <Component {...props} {...routeProps} />
        }} />;
    }

    if (firebase.isUserAuthenticated() && !isAllowed) {
      redirect = Routes.LANDING;
    }

    if (this.props.history.location.pathname === this.props.path) {
      return (
        <Redirect
          to={{
            pathname: redirect
          }}
        />
      );
    } else {
      return (null)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.currentUser
  };
};

export default connect(mapStateToProps, null)(withRouter(ProtectedRoute));
