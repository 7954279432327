import React, { Component } from 'react';
import InstagramLogo from 'assets/instagram-logo-purple@3x.png';
import LinkIcon from 'assets/link-purple@3x.png';
import MailIcon from 'assets/mail-purple@3x.png';
import YoutubeLogo from 'assets/youtube-logo-purple@3x.png';
import Button from 'components/Button';
import LinkedInLogo from 'assets/linkedin-logo-purple@3x.png';
import TwitterLogo from 'assets/twitter-logo-purple@3x.png';
import './eventcard.css';

export default class EventCard extends Component {
  render() {
    let {
      event,
      organization
    } = this.props;

    let {
      startdate,
      enddate,
      tagline,
      location,
      organizer,
      name,
      date,
      time,
      ticket_url,
      logo,
      linkedin,
      youtube,
      facebook,
      twitter,
      website,
      instagram,
      email
  } = event;

    return (
      <div className="restage-event-eventcard-container d-flex flex-column align-items-center">
        <div>
          <img className="restage-event-eventcard-logo" src={logo} />
        </div>
        <div>
          <div className="restage-font-45 restage-font-deep-purple restage-poppins restage-font-semibold">{organization.name}</div>
        </div>
        <div className="d-flex restage-event-eventcard-icons align-items-center">
          <a target="_blank" className="d-flex align-items-center justify-content-center" href={website}>
            <img
              className="restage-event-eventcard-instagram"
              alt="Link"
              src={LinkIcon}
            />
          </a>
          <a target="_blank" className="d-flex align-items-center justify-content-center" href={instagram}>
            <img
              className="restage-event-eventcard-instagram"
              alt="Instagram logo."
              src={InstagramLogo}
            />
          </a>
          <a target="_blank" className="d-flex align-items-center justify-content-center" href={twitter}>
            <img
              className="restage-event-eventcard-twitter"
              src={TwitterLogo}/>
          </a>
          <a target="_blank" className="d-flex align-items-center justify-content-center" href={linkedin}>
            <img
              className="restage-event-eventcard-linkedin"
              src={LinkedInLogo}/>
          </a>
          <a target="_blank" className="d-flex align-items-center justify-content-center" href={youtube}>
            <img
              className="restage-event-eventcard-youtube"
              src={YoutubeLogo}/>
          </a>
          <a target="_blank" className="d-flex align-items-center justify-content-center" href={`mailto:${email}`}>
            <img
              className="restage-event-eventcard-instagram"
              alt="Mail"
              src={MailIcon}
            />
          </a>
        </div>
        <div className="restage-event-eventcard-details">
          <div>
            <div style={{borderBottom: '1px solid #E3DBE6', marginBottom: '10px'}} className="restage-montserrat restage-font-bold restage-font-30 restage-font-brand-secondary">Upcoming Event</div>
            <div className="restage-montserrat restage-font-20 restage-font-bold restage-font-brand-secondary">{name}</div>
            <div className="restage-montserrat restage-font-20 restage-font-brand-secondary">{date}</div>
            <div className="restage-montserrat restage-font-20 restage-font-brand-secondary">{time}</div>
            <a href={ticket_url} style={{ wordBreak: 'break-word' }} target="_blank" rel="noopener noreferrer" className="restage-montserrat restage-font-20 restage-font-3E0359">{ticket_url}</a>
          </div>
          {/*}<div>
            <div className="restage-montserrat restage-font-bold restage-font-30 restage-font-brand-secondary">Event Organizer</div>
            <div className="restage-montserrat restage-font-24 restage-font-brand-secondary">{organization.name}</div>
          </div>
          <div>
            <div className="restage-montserrat restage-font-bold restage-font-30 restage-font-brand-secondary">Website</div>
            <div className="restage-montserrat restage-font-24 restage-font-brand-secondary">{website}</div>
          </div>*/}
          {/*<div>
            <div></div>
            <div></div>
          </div>*/}
        </div>
      </div>
    )
  }
}
