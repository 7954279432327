import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './newslettersignup.actions';
import { post } from '../Api'

const basePath = "newsletter"

export const signupNewsletter = function* (action) {
  let { email } = action.payload;
  try {
    const response = yield call(post, `${basePath}/${email}`);
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.SIGNUP_NEWSLETTER_SUCCESS, data: { email } });
    } else {
      throw response;
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    if (error.status === 409) {
      yield put({ type: ActionTypes.SIGNUP_NEWSLETTER_FAILURE, error: true, errorMessage: "You have already subscribed." });
    }
  }
}

function* newslettersignup() {
  yield all([
    takeLatest(ActionTypes.SIGNUP_NEWSLETTER, signupNewsletter),
  ]);
}

export default newslettersignup;
