/*import React, { Component } from 'react'
import Accordion from 'components/Accordion'
import './packages.css'
export default class Packages extends Component {
  getPanels() {
    const { packages } = this.props
    const buffer =
      let content = () => {
        return (
          <div className="restage-background-transparent">
            Blah
          </div>
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row">
            <div>
              <div className='body-copy-bold-brand'></div>
              <div className="body-copy-tertiary"></div>
            </div>
          </div>
        ),
        content
      }

      return [
        panel,
        panel,
        panel
      ]
  }
  render() {
    const { className, label } = this.props;
    return (
      <div className={className}>
        <div className="restage-font-24 restage-font-333333">{label}</div>
        <Accordion background="transparent" panels={this.getPanels()} />
      </div>
    )
  }
}*/


import React, { Component } from 'react'
import Accordion from 'components/Accordion'
import './packages.css'

export default class Packages extends Component {
  getPanels() {
    const { packages } = this.props
    return packages.map(({ name, price, description, contents, limit }) => {
      let content = () => {
        return (
          <div className="restage-package-contents">
              <div>
                <div>Description</div>
                <div className="body-copy-tertiary">{description}</div>
              </div>
              <div>
                <div>Package Contents</div>
                <div className="body-copy-tertiary">{contents}</div>
              </div>
              <div>
                <div># of Available Packages</div>
                <div className="body-copy-tertiary">{limit}</div>
              </div>
          </div>
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row">
            <div>
              <div className='body-copy-bold-brand'>{name}</div>
              <div className="body-copy-tertiary">{price}</div>
            </div>
          </div>
        ),
        content
      }
      return panel
    });
  }

  render() {
    const { className, label } = this.props;
    return (
      <div className={className}>
        <div className="restage-font-24 restage-font-333333">{label}</div>
        <Accordion background="transparent" panels={this.getPanels()} />
      </div>
    )
  }
}
