import React from "react";
import * as d3 from "d3";


function translate(x, y) {
  return `translate(${x}, ${y})`;
}

export const PieChart = (props) => {
  const { data } = props;
  const height = 200;
  const width = 200;
  const values = data.map((item) => item.value)
  const labels = {}
  data.map((item) => {
    labels[item.value] = item.label
  })
  let pie = d3.pie()(values);

  return (
    <svg height={height} width={width}>
      <g transform={`translate(${width / 2},${height / 2})`}>
        <Slice labels={labels} pie={pie} />
      </g>
    </svg>
  );
};

const Slice = props => {
  let { pie, transform, labels } = props;

  let arc = d3
    .arc()
    .innerRadius(0)
    .outerRadius(100);

  let interpolate = d3.interpolateRgb("#3E0359", "#EB5B49");

  return pie.map((slice, index) => {
    let sliceColor = interpolate(index / (pie.length - 1));
    let translateValues = arc.centroid(slice);
    let xOffset = 20;
    let yOffset = 10
    let fontSize = 12;
    if (["Male Identifying", "Female Identifying"].includes(labels[slice.data])) {
      xOffset = 40
      fontSize = 8
    }



    if (["Other"].includes(labels[slice.data])) {
      yOffset = 20
      xOffset = 25;
    }

    if (["35-40", "21-25"].includes(labels[slice.data])) {
      yOffset = 0
    }

    if (["40+"].includes(labels[slice.data])) {
      yOffset = 15
    }

    let x = translateValues[0] - xOffset
    let y = translateValues[1] - yOffset

    return (
      <g>

        <path d={arc(slice)} fill={sliceColor} />
        <text transform={translate(x, y)}
              style={{fill: "white", fontSize: `${fontSize}px`}}
              dy=".35em"
              className="piechart-label">
          {labels[slice.data]}
        </text>
      </g>
    )
  });
};
