import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, withRouter  } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Audience from 'screens/experiencethis/audience/audience.screen';
import Details from 'screens/experiencethis/details/details.screen';
import Upload from 'screens/experiencethis/upload/upload.screen';
import ExperienceThis from 'screens/experiencethis/experiencethis';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { Routes } from 'constants/Routes';

const routes = [
  { path: Routes.EXPERIENCE_THIS_AUDIENCE, screen: Audience },
  { path: Routes.EXPERIENCE_THIS_DETAILS, screen: Details },
  { path: Routes.EXPERIENCE_THIS_UPLOAD, screen: Upload }
]

class ExperienceThisRoutes extends Component {

  render() {
    console.log("ddd")
    return (
      <ExperienceThis history={this.props.history}>
        <TransitionGroup>
          <CSSTransition
            key={this.props.history.location.pathname}
            timeout={600}
            classNames='restage-route-transition'>
            <Switch>
              {
                routes.map(({ path, screen }) => {
                        console.log("dddd", "path", path, screen)
                  return (

                    <ProtectedRoute key={path} exact path={path} component={screen}/>
                  )
                })
              }
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </ExperienceThis>
    )
  }
}

export default withRouter(ExperienceThisRoutes);
