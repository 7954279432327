import React, { Component } from 'react';
import './Button.css';

class Button extends Component {
  static defaultProps = {
    type: "button",
    icon: null
  }

  getSize(className) {
    if (this.props.size === null) {
      return className;
    }

    if (this.props.size === 'lg') {
      return className.concat(["button-lg"]);
    }

    return className;
  }

  getIcon() {
    if (this.props.icon === null) {
      return null;
    }

    return (
      <img style={{marginRight: '15px'}} src={this.props.icon}/>
    );
  }

  onClick(e) {
    this.props.onClick.bind(this);
    //e.stopPropagation();
  }

  render() {
    let className = ["button"];
    className = (this.props.rightRounded === true ? className.concat(["button-right-rounded"]) : className);
    className = (this.props.className !== null ? className.concat([this.props.className]) : className);
    className = this.getSize(className);

    return (
      <button
        disabled={this.props.disabled}
        type={this.props.type}
        className={className.join(" ")}
        onClick={this.onClick.bind(this)}
        >{this.getIcon()}{this.props.label.toUpperCase()}</button>
    );
  }
}

export default Button;
