import React from "react"
import "./lineMenu.css"
import "./SubscribeModal/SubscribeModal.css"

export default class LineMenu extends React.Component{
  openModal() {
    let {open} = this.props;
    if (open) return "expt-subscribe-modal";
    return "expt-subscribe-modal-inactive"
  }

  getActiveClass(className) {
    let { open } = this.props;

    if (open) {
      return `${className} active`
    }
    return className
  }

  render() {
      let { onClick } = this.props;

      return (
        <div className="expt-menuLines" onClick={onClick.bind(this)} >
            <div className={this.getActiveClass("menuLine1")}></div>
            <div className={this.getActiveClass("menuLine2")}></div>
            <div className={this.getActiveClass("menuLine3")}></div>
        </div>
      )
    }
  }
