import React, { Component } from 'react';
import Dots from 'common/dots';
import './audienceinfocomponents.css';
import { Routes } from 'constants/Routes';

const OFFSET_FACTOR_DK = 24;
const OFFSET_FACTOR_MB = 79.466;

const VIEWPORT_WIDTH_DK = 1920;
const VIEWPORT_WIDTH_MB = 375;

const STARTING_INDEX = 0;

export default class AudienceInfoComponents extends Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      activeIndex: STARTING_INDEX,
      target: "_blank",
      positions: this.getPositions(STARTING_INDEX),
      offsetFactor: this.getOffsetFactor(),
      isScrolling: false,
      currentX: 0,
      viewportWidth: this.getViewportWidth(),
      startX: 0,
      scrollPanelStyle: {}
    }
    this.onResize = this.onResize.bind(this);
  }

  getOffsetFactor() {
    let { innerWidth } = window;
    let offsetFactor = OFFSET_FACTOR_DK;
    if (innerWidth < 768) {
      offsetFactor = OFFSET_FACTOR_MB;
    }

    return offsetFactor;
  }

  getViewportWidth() {
    let { innerWidth } = window;
    let viewportWidth = VIEWPORT_WIDTH_DK;
    if (innerWidth < 768) {
      viewportWidth = VIEWPORT_WIDTH_MB;
    }

    return viewportWidth;
  }

  getPositions(activeIndex) {
    let { items } = this.props;
    let positions = {};
    let offsetFactor = (this.state ? this.state.offsetFactor : this.getOffsetFactor());
    positions[activeIndex] = {
      translateX: 0
    }
    //prev
    let opacity = 1;
    let current = 0;
    for (let i = 1; i < items.length/2 ; i++) {
      current = activeIndex - i;
      if (current < 0 && activeIndex !== 0) {
        current = items.length + current
      } else if (activeIndex === 0) {
        current = items.length + current;
      }

      let newTranslateX = -i * offsetFactor;
      opacity = 1;

      if (this.state && this.state.positions && Object.keys(this.state.positions).length === items.length) {
        if ((newTranslateX < 0) && (this.state.positions[current].translateX > 0)) {
          opacity = 0;
        }
        if ((newTranslateX > 0) && (this.state.positions[current].translateX < 0)) {
          opacity = 0;
        }
      }

      positions[current] = {
        translateX: -i * offsetFactor,
        opacity
      }
    }

    //next
    for (let i = 1; i <= items.length/2; i++) {
      let current = activeIndex + i;
      if (current > items.length - 1) {
        current = current - items.length
      }
      let newTranslateX = i * offsetFactor;
      opacity = 1;

      if (this.state && this.state.positions && Object.keys(this.state.positions).length === items.length) {
        if ((newTranslateX > 0) && (this.state.positions[current].translateX < 0)) {
          opacity = 0;
        }
        if ((newTranslateX < 0) && (this.state.positions[current].translateX > 0)) {
          opacity = 0;
        }
      }
      positions[current] = {
        translateX: i * offsetFactor,
        opacity
      }
    }
    return positions
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, false);
  }

  onResize(event) {
    let that = this;
    let offsetFactor = this.getOffsetFactor();
    let viewportWidth = this.getViewportWidth();
    this.setState({
      offsetFactor,
      viewportWidth
    }, () => {
      let { activeIndex } = that.state;
      this.setState({
        positions: this.getPositions(activeIndex)
      });
    });
  }

  onKeyUp(event, forward) {
    console.log(event, forward);
  }

  handleNavigate(forward) {
    let { items } = this.props;
    let { activeIndex } = this.state;

    if (!forward) {
      activeIndex++;
    } else {
      activeIndex--;
    }

    if (activeIndex === items.length) {
      activeIndex = 0;
    }

    if (activeIndex < 0) {
      activeIndex = items.length-1;
    }

    this.setState({
      activeIndex,
      positions: this.getPositions(activeIndex)
    });
  }

  getStyle(index) {
    let { positions, offsetFactor } = this.state;
    let { translateX, opacity } = positions[index];
    /*let opacity = 1;
    let maskBuffer = (offsetFactor === OFFSET_FACTOR_DK ? 100 : 250);
    if (Math.abs(translateX) > maskBuffer) {
      opacity = 0;
    }*/
    return {
      transform: `translateX(${positions[index].translateX}vw)`,
      opacity
    }
  }

  onMouseDown(event) {
    this.setState({
      isScrolling: true,
      currentX: event.clientX,
      startX: event.clientX
    });
  }

  onMouseUp(event) {
    let { startX, activeIndex, offsetFactor } = this.state;
    let finalXdifference = (event.clientX - startX)/1920 * 100;
    let indexDiff = Math.round(finalXdifference / (offsetFactor/4));

    let forward = (indexDiff >= 0 ? true : false)
    for (let i = 0; i < Math.abs(indexDiff); i++) {
      this.handleNavigate(forward)
    }

    this.setState({
     isScrolling: false,
     scrollPanelStyle: {}
    });
  }

  onTouchMove(event) {
    const { startX } = this.state;
    if (this.state.isScrolling) {
      let touchedObject = event.changedTouches[0];
      let { clientX } = touchedObject;
      let scrollPanelStyle = {
        transform: `translateX(${clientX - startX})/1920 * 100}vw)`/*,
        webkitTransform: `-webkit-translateX(${clientX} - startX)/1920 * 100}vw)`*/
      }
      this.setState({
        scrollPanelStyle,
        currentX: clientX
      })
    }
  }

  onTouchStart(event) {
    let touchedObject = event.changedTouches[0];
    let { clientX } = touchedObject;

    this.setState({
      isScrolling: true,
      currentX: clientX,
      startX: clientX
    });
  }

  onTouchEnd(event) {
    let { startX, activeIndex, offsetFactor, viewportWidth } = this.state;
    let touchedObject = event.changedTouches[0];
    let { clientX } = touchedObject;
    let finalXdifference = (clientX - startX)/viewportWidth * 100;
    let indexDiff = Math.round(finalXdifference / offsetFactor*2);

    let forward = (indexDiff >= 0 ? true : false)
    for (let i = 0; i < Math.abs(indexDiff); i++) {
      this.handleNavigate(forward)
    }

    this.setState({
     isScrolling: false,
     scrollPanelStyle: {}
    });
  }

  onMouseMove(event) {
    const { startX } = this.state;
    if (this.state.isScrolling) {
      let scrollPanelStyle = {
        transform: `translateX(${(event.clientX - startX)/1920 * 100}vw)`
      }
      this.setState({
        scrollPanelStyle,
        currentX: event.clientX
      })
    }
  }

  handleOnChangeDots(index) {
    this.setState({
      activeIndex: index,
      positions: this.getPositions(index)
    })
  }


  render() {
    const { items } = this.props;
    const { scrollPanelStyle, activeIndex } = this.state;
    return (
      <div className="restage-event-audienceinfo-container d-flex align-items-center justify-content-between flex-column">
        <div className="restage-montserrat restage-font-bold restage-font-30 restage-font-brand-secondary">Audience Profile</div>
        <div
          onTouchStart={this.onTouchStart.bind(this)}
          onTouchEnd={this.onTouchEnd.bind(this)}
          onTouchMove={this.onTouchMove.bind(this)}
          onMouseUp={this.onMouseUp.bind(this)}
          onMouseDown={this.onMouseDown.bind(this)}
          onMouseMove={this.onMouseMove.bind(this)}
          style={scrollPanelStyle}
          ref={this.ref}
          className="d-flex restage-event-audienceinfo-card-wrapper">
        {
              items.map((item, index)=> {

                return (
                  <div style={this.getStyle(index)} key={index} className="restage-event-audienceinfo-card">
                    {item()}
                  </div>
                )
              })
          }
        </div>
        <Dots
          activeIndex={activeIndex}
          onChange={this.handleOnChangeDots.bind(this)}
          className=""
          items={items} />
      </div>
    )
  }
}
