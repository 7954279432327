import React, { Component } from 'react';
import Video from 'common/video';
import PlayButtonIcon from 'assets/play-button@3x.png';
import PlaylistItem from './playlistItem';
import './playlistButton.css';

export default class PlaylistButton extends Component {
  constructor(props) {
      super(props)
      this.state = {
        menuVisible: false
      }
  }

  toggleMenu() {
  if (true) return;
    this.setState({
      menuVisible: !this.state.menuVisible
    })
  }

  getMenu() {
    let {
      onChangePlaylist,
      playlists
    } = this.props;

    let {
      menuVisible
    } = this.state;

    if (!menuVisible) return null;
    return (
      <div className="expt-playlistButton-items">
        {
          playlists.map((item, index)=>{
            let { title } = item;
            return <PlaylistItem key={index} onClick={onChangePlaylist.bind(this, index)} playlistTitle={title} title={title}/>
          })
        }
      </div>
    )
  }

  render() {
    let { selectedPlaylist, videoTitle, videoDuration } = this.props;
    let {
      title,
      duration
    } = selectedPlaylist
    return(
      <div className="expt-playlistButton-container">
        {this.getMenu()}
        <button onClick={this.toggleMenu.bind(this)} className="restage-text-align-start expt-playlistButton d-flex align-items-center justify-content-between">
          <div className="restage-font-medium restage-poppins restage-font-brand-gray restage-font-16">
            <div>{title}</div>
            <div className="d-flex">
              <div>{videoTitle}</div>&nbsp;
              <div>{videoDuration}</div>
            </div>
          </div>
          <div><img className="expt-playlist-button-icon" src={PlayButtonIcon}/></div>
        </button>
      </div>
    )
  }

}
