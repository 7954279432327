import React, { Component } from 'react';
import Detail from 'common/Detail';
import Calendar from 'components/Calendar';
import Places from 'common/Places';
import TextField from 'components/TextField';
import ButtonGroup from 'components/ButtonGroup';
import { Routes } from 'constants/Routes';
import { isFilled } from 'common/helpers/validators';
import './details.css';

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: {
        value: "",
        error: false
      },
      place_id: {
        value: props.place_id,
        error: false
      },
      place_name: {
        value: props.place_name
      },
      eventdate: {
        value: props.eventdate,
        error: false
      },
      description: {
        value: props.description,
        error: false
      }
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  validate() {
    let fields = new Set();
    fields.add(this.isFieldValid(isFilled, "name"));
    fields.add(this.isFieldValid(isFilled, "description"));
    fields.add(this.isFieldValid(isFilled, "eventdate"));
    fields.add(this.isFieldValid(isFilled, "place_id"));

    return !fields.has(false);
  }

  isFieldValid(validator, key) {
    let isValid = true;

    isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }

  onSubmit() {
    this.props.history.push(Routes.EXPERIENCE_THIS_AUDIENCE)
    if (!this.validate()) return null;
    //this.props.history.push(Routes)
  }

  onChangeDate(key, value) {
    this.setState({
      [key]: {
        value,
        error: false
      },
    });
  }

  onAutocompleteOptionSelection(selected) {
    let place = selected[0];
    this.setState({
      place_id: {
        value: place.placeId,
        error: false
      },
      place_name: {
        value: place.description
      }
    });
  }

  render() {
    let {
      name,
      eventdate,
      place_name,
      place_id,
      description,
      place_description
    } = this.state;

    return(
      <div>
        <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Add Details" subHeader="Enter the event details" />
        <div className="experiencethis-details-form">
          <TextField
            fontStyle="tertiary"
            name="name"
            placeholder="Event Name"
            value={name.value}
            errorMessage="Event name cannot be empty."
            error={name.error}
            onChange={this.onChange.bind(this)}
            label="Event Name"
            size="full" />
          <Places
            isGeopoliticalRegionAllowed={false}
            fontStyle="tertiary"
            label="Event Venue"
            name="venue"
            value={place_name.value ? place_name.value : place_description}
            placeholder="Event Venue"
            errorMessage="Please select the venue of the event."
            error={place_id.error}
            onOptionSelection={this.onAutocompleteOptionSelection.bind(this)} />
          <Calendar
            timeFormat={false}
            fontStyle="tertiary"
            placeholder="Start date and time"
            value={(eventdate.value ? new Date(eventdate.value) : null)}
            errorMessage="Start date cannot be empty."
            error={eventdate.error}
            onChange={this.onChangeDate.bind(this, "eventdate")}
            label="End Date"
            size="full"/>
          <TextField
            fontStyle="tertiary"
            name="description"
            value={description.value}
            errorMessage="Description cannot be empty."
            error={description.error}
            isTextArea={true}
            onChange={this.onChange.bind(this)}
            label="Event Description"
            placeholder="Event description"
            size="full" />
        </div>
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 2 of 3</div>
          <ButtonGroup
            labelPrimary="NEXT"
            labelSecondary="BACK"
            onClickPrimary={this.onSubmit.bind(this)}
            onClickSecondary={(()=>{})} />
        </div>
      </div>
    )
  }
}
