import React, { Component } from 'react';
import Disc from 'assets/icons/Disc.png';

import './listitem.css';

class ListItem extends Component {
  render() {
    let { item, expand, itemClassName, type } = this.props;

    if (itemClassName.length === 0) {
      itemClassName = "body-copy-tertiary-small";
    }

    //let className = ["d-flex", "flex-row", "col-12", "listitem", "align-items-start"]
    let className = ["d-flex", "flex-row", "col-12", "listitem", "align-items-start"]

    console.log(itemClassName);

    if (!expand) {
      className.push("col-sm-6");
    }

    let style = { marginTop: '8px', width: '7px', height: '7px', backgroundColor: '#680894', borderRadius: '100%' }
    if (type === 'hollow') {
      style = { marginTop: '8px', width: '7px', height: '7px', border: '1px solid #680894', backgroundColor: 'transparent', borderRadius: '100%' }    
    }



    return (
      <div className={className.join(" ")}>
        <div style={style}>{/*}<img alt="Bullet in bulleted list." src={Disc} />*/}</div>
        <div className={`${itemClassName} d-flex align-items-center`}>{item}</div>
      </div>
    );
  }
}

export default ListItem;
