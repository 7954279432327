import React, { Component } from 'react';
import Detail from 'common/Detail';
import ButtonGroup from 'components/ButtonGroup';
import AutocompleteEntryGroup from 'common/AutocompleteEntryGroup';
import Places from 'common/Places';
import './audience.css';

export default class Audience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hashtag: {
        value: "",
        error: false
      }
    }
  }

  onSubmit() {

  }

  onAutocompleteOptionSelection() {

  }

  onClickAddHashtag() {

  }

  onChangeAutocompleteHashtags() {

  }

  onMouseDownAutocompleteOptionHashtag() {

  }

  onBlurAutocompleteHashtag() {

  }

  render() {
    let { hashtag } = this.state;
    return(
      <div>
        <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Audience" subHeader="Define your target audience for the event. The audience attributes selected will be shown in the right panel." />
        <div className="experiencethis-audience-form">
          <Places
            isAddressAllowed={false}
            isGeopoliticalRegionAllowed={true}
            fontStyle="tertiary"
            label="Locations"
            onOptionSelection={this.onAutocompleteOptionSelection.bind(this)}
            placeholder="Enter a location" />
          <AutocompleteEntryGroup
            fontStyle="tertiary"
            label="Hashtags"
            placeholder="Enter hashtags"
            name="Hashtags"
            errorMessage="Please select at least one hastag cannot be an empty string."
            buttonLabel="Add"
            value={this.state.hashtag.value}
            error={this.state.hashtag.error}
            onClickAddEntry={this.onClickAddHashtag.bind(this)}
            onChange={this.onChangeAutocompleteHashtags.bind(this)}
            results={[]}
            onMouseDownOption={this.onMouseDownAutocompleteOptionHashtag.bind(this)}
            onBlur={this.onBlurAutocompleteHashtag.bind(this)}
            size="full"/>
        </div>
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 3 of 3</div>
          <ButtonGroup
            labelPrimary="SUBMIT"
            labelSecondary="BACK"
            onClickPrimary={this.onSubmit.bind(this)}
            onClickSecondary={(()=>{})} />
        </div>
      </div>
    )
  }
}
