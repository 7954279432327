import {Upload} from "tus-js-client";

export const getUploader = (file) => {
  var upload = new Upload(file, {
      endpoint: "http://localhost:1080/files/",
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
          filename: file.name,
          filetype: file.type
      },
      onError: function(error) {
          console.log("Failed because: " + error)
      },
      onProgress: function(bytesUploaded, bytesTotal) {
          var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
          console.log(bytesUploaded, bytesTotal, percentage + "%")
      },
      onSuccess: function() {
          console.log("Download %s from %s", upload.file.name, upload.url)
      }
  })

  // Start the upload
  upload.start()
  console.log("cough")
  return upload
}
