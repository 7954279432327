import React, { Component } from 'react';
import './dot.css';

export default class Dot extends Component {
  getClassName() {
    let { active } = this.props;
    if (active) return "expt-home-hero-dot restage-background-coral";
    return "expt-home-hero-dot restage-home-hero-dot-inactive"
  }

  render() {
    let { onClick } = this.props;
    return (
      <div onClick={onClick.bind(this)} className={this.getClassName()} />
    )
  }
}
