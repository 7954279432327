import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import EventDashboard from '../screens/event/dashboard/eventdashboard.screen';
import ManageEvent from '../screens/event/dashboard/manage/manage.screen';
import ViewEvent from '../screens/event/dashboard/view/view.screen';
import EditEvent from '../screens/event/dashboard/edit/edit.screen';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { Routes } from 'constants/Routes';

const {
  MANAGE_EVENT,
  EDIT_EVENT,
  VIEW_EVENT
} = Routes;

const routes = [
  { path: MANAGE_EVENT, Component: ManageEvent },
  { path: EDIT_EVENT, Component: EditEvent },
  { path: VIEW_EVENT, Component: ViewEvent },
];

const EVENT_DASHBOARD_URLS = [MANAGE_EVENT, EDIT_EVENT, VIEW_EVENT];

class EventDashboardRoutes extends Component {
  render() {
    let { pathname } = this.props.history.location;
    //if (!EVENT_DASHBOARD_URLS.includes(pathname)) return <Redirect to={MANAGE_EVENT} />;
    return (
      <EventDashboard>
        <Switch>
          {routes.map(({ path, Component }) => (
            <ProtectedRoute key={path} path={path} component={Component}/>
          ))}
        </Switch>
      </EventDashboard>
    )
  }
}

export default withRouter(EventDashboardRoutes);
