import React, { Component } from 'react';
import './experiencethis.css';
import { Routes } from 'constants/Routes';
import Document from 'assets/icons/document@3x.png';
import User from 'assets/user@3x.png';
import Tag from 'assets/tag@3x.png';
import Tab from 'common/Tab';
import TabGroup from 'common/TabGroup';

const {
  EXPERIENCE_THIS_UPLOAD,
  EXPERIENCE_THIS_DETAILS,
  EXPERIENCE_THIS_AUDIENCE
} = Routes;

const tabs = [
  { alt: "Upload", icon: Document, label: "Upload", route: EXPERIENCE_THIS_UPLOAD },
  { alt: "Details", icon: User, label: "Details", route: EXPERIENCE_THIS_DETAILS },
  { alt: "Audience", icon: Tag, label: "Audience", route: EXPERIENCE_THIS_AUDIENCE }
]

export default class ExperienceThis extends Component {
  onClickTab(Route) {

  }

  render() {
    let {
      history
    } = this.props;
    return(
      <div style={{paddingTop: '30px'}} className="container">
        <TabGroup>
          {
            tabs.map(({alt, icon, label, route}, index)=>{
              let isComplete = false;
              let active = true;


              return (
                <Tab
                  isComplete={isComplete}
                  active={active}
                  alt={alt}
                  key={index}
                  icon={icon}
                  label={label}
                  history={history}
                  route={route}
                  onClick={this.onClickTab.bind(this, route)} />
                )
            })
          }
        </TabGroup>
        <div style={{paddingTop: '65px', paddingBottom: '65px'}}>{this.props.children}</div>
      </div>
    )
  }
}
