import React, { Component } from 'react';
import { Routes } from 'constants/Routes';
import Button from 'components/new/Button';
import GetStartedPanel from 'common/getstartedpanel.component';
import Calendar from 'assets/calendar@3x.png';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import EventTile from 'common/eventtile.component';
import Wrapper from 'components/Wrapper';
import { connect } from 'react-redux';
import { getEvents } from 'services/events/events.actions';
import { bindActionCreators } from 'redux';
import './eventshome.css';

class EventsHome extends Component {
  static defaultProps = {
    events: []
  }

  componentWillMount() {
    if (this.props.user !== null) {
      this.props.getEvents(this.props.user.id, null);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user === null && prevProps.user !== null) {
      this.props.getEvents(this.props.user.id, null);
    }
  }

  goToEventDetails() {
    this.props.history.push(Routes.EVENT_DETAILS);
  }

  goToEvent(event) {
    console.log("eventtts", event, `/events/${event.id}/dashboard/edit`)
    this.props.history.push(`/events/${event.id}/dashboard/edit`);
  }

  getEventsHomeUI() {
    if (this.props.events.length === 0) {
      return (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <GetStartedPanel
            title="Create Your First Event"
            items={[
              "Give us the basic details to get started.",
              "Tell us about your audience to improve recommendations.",
              "List your available sponsorships for sponsors to see.",
              "Submit the event for review and publishing."
            ]} />
          <div>
            <Button size="md" type="tertiary" onClick={this.goToEventDetails.bind(this)} label="CREATE EVENT"/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="eventshome-container d-flex flex-wrap">
        {
          this.props.events.map((item, index)=>{
            let { event, assets } = item;
            let location = event.location;
            let { startdate, enddate, name, description} = event;


            let start = new Date(startdate);
            let end = new Date(enddate);

            let poster;

            if (assets) {
              if (assets.length > 0) {
                let posters = assets.filter((asset) => asset.asset_type_id === 1);
                if (posters.length > 0){
                  poster = posters[0];
                }
              }
            }

            return (
              <EventTile
                score={100}
                description={description}
                key={index}
                poster={poster}
                onClick={this.goToEvent.bind(this, event)}
                name={name}
                startdate={start.toLocaleDateString()}
                enddate={end.toLocaleDateString()}
                formatted_address={location.name} />
            );

          })
        }
        </div>
      )
    }
  }

  getCreateEventButton() {
    if (this.props.events.length === 0) return (null);
    return (
      <div>
        <Button size="md" type="tertiary" onClick={this.goToEventDetails.bind(this)} label="CREATE EVENT"/>
      </div>
    )
  }

  render() {
    if (!this.props.userData) {
      return (null);
    }

    return (
    <div className="eventshome-wrapper container">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px'}} header="My Events"/>
            {this.getCreateEventButton()}
          </div>
          <Divider  style={{marginBottom: '25px'}}/>
        </div>
        {this.getEventsHomeUI()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    events: state.events.currentUserEvents,
    user: state.users.user,
    userData: state.users.user
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getEvents,
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(EventsHome);
