import React from "react"
import Dot from "./dot"
import Video from 'common/video';
import "./dotNav.css"

export default class DotNav extends React.Component{
  changeDot(index) {
    this.props.onChangeVideo(index);
  }

  getDots() {
    let {
      currentlyPlayingVideoId,
      dotsData
    } = this.props;
    return (
      <div className="d-flex expt-hero-dotsNav-container">
        {
          dotsData.map((dot, index) => {
            return (
              <Dot key={index}
                onClick={this.changeDot.bind(this, index)}
                active={currentlyPlayingVideoId == index}
                index={index}/>
            )
          })
        }
      </div>
    )
}

  render () {
    return this.getDots()
  }
}
