import React, { Component } from 'react';
import logo from "assets/restage-r.svg"
import "./navbar.css"
import LineMenu from "./lineMenu"
//import SubscribeModal from "screens/SubscribeModal/SubscribeModal"

export default class Navbar extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
  }

  handleOnClick() {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    let {
      subscribeEmail
    } = this.props;

    let {
      open
    } = this.state;
    return(
      <div>
        <div className="d-flex expt-navbar-container justify-content-between">
          <div>
            <img className="expt-logo" src={logo}/>
          </div>
          <LineMenu open={open} onClick={this.handleOnClick.bind(this)}/>
        </div>
        {/*<SubscribeModal subscribe={subscribeEmail.bind(this)} open={open}/>*/}
      </div>
    )
  }
}
