import React, { Component } from 'react';
import Upload from 'components/Upload';
import Detail from 'common/Detail';
import ButtonGroup from 'components/ButtonGroup';
import { Routes } from 'constants/Routes';
import { getUploader } from 'common/helpers/tus'
import plupload from 'plupload';

export default class ExperienceThisUpload extends Component {
  componentDidMount() {

  }

  onUpload(event, files) {
    getUploader(files[0])

  }

  onSubmit() {
    this.props.history.push(Routes.EXPERIENCE_THIS_DETAILS)
  }

  render() {
    return(
      <div>
        <div>
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Upload Content" subHeader="Add content you've captured at events." />

          <Upload
            accept="image/*, video/*"
            multiple={false}
            error={false}
            errorMessage="Please upload at least one photo."
            label="Content - Photos & Videos"
            onSubmit={this.onUpload.bind(this)} />
          </div>
          <div style={{marginTop: '50px'}}>
            <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 1 of 3</div>
            <ButtonGroup
              labelPrimary="NEXT"
              labelSecondary="BACK"
              onClickPrimary={this.onSubmit.bind(this)}
              onClickSecondary={(()=>{})} />
          </div>
      </div>
    )
  }
}
