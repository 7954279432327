import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import Home from 'screens/home/Home';
import NavBar from 'components/NavBar';
import SettingsModule from './routes/settings.routes';
import Test from 'screens/test.screen';
import PDFs from 'screens/documentrepository/pdfs.screen';
import Negotiations from './screens/negotiations/negotiations-modal.screen';
import Sandbox from 'screens/sandbox/sandbox.screen';
import Verify from 'screens/verify/verify.screen';
import Resend from 'screens/verify/resend.screen';
import EventSponsors from 'screens/admin/eventsponsors.screen';
import EventRankings from 'screens/admin/eventrankings.screen';
import Blog from 'screens/blog/blog.screen';
import Post from 'screens/blog/post.screen';
import EventRankingsDivision from 'screens/admin/eventrankingsdivision.screen';
import SponsorRankings from 'screens/admin/sponsorrankings.screen';

import AdminEventReview from 'screens/admin/events/review/review.screen';
import AdminEvents from 'screens/admin/events/events.screen';
import AssetUpload from 'screens/sponsor/assets/assets.screen';

import EventDashboardRoutes from './routes/eventdashboard.routes';

import CreateEventRoutes from './routes/createevent.routes';
import CreatePortfolioRoutes from './routes/createportfolio.routes';

import ExperienceThisRoutes from './routes/experiencethis.routes';
import CompaniesRoutes from './routes/companies.routes';
import HOY from 'screens/company/hoy.screen';
import Search from 'screens/event/search/search.screen';
import Event from 'screens/event/page/event.screen';
import {default as OldEvent} from 'screens/event/page/old/event.screen';
import EventsHome from 'screens/event/eventshome.screen';

import SponsorHome from 'screens/sponsor/sponsorhome.screen';
import Portfolio from 'screens/sponsor/portfolio/portfolio.screen';
import CreateAccountModal from 'common/modals/createaccount.modal';
import PrivacyPolicy from 'screens/legal/privacypolicy.screen.js';
import Resources from 'screens/learnmore/resources.screen.js';
import EventOrganizer from 'screens/learnmore/eventorganizer.screen.js';
import Sponsor from 'screens/learnmore/sponsor.screen.js';
import SignUp from 'screens/signup/signup.screen';
import NotFound from 'screens/NotFound.screen';
import GetStarted from 'screens/onboarding/getstarted/getstarted.screen';
import CreateAccount from 'screens/onboarding/create/createaccount.screen';
import Intro from 'screens/onboarding/intro/intro.screen';
import LogIn from 'screens/login/login.screen';
import ForgotPassword from 'screens/forgotpassword/forgotpassword.screen';
import Loader from 'components/Loader';
import firebase from './firebase/Firebase';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { Routes } from 'constants/Routes';
import { UserTypes } from 'constants/UserTypes';
import {Elements, StripeProvider} from 'react-stripe-elements';
import { setFirebase } from './firebase/firebase.actions';
import { createUser } from 'services/signup/signup.actions';
import { resend } from 'services/verify/verify.actions';
import { setCurrentUser, getUser } from 'services/users/users.actions';
import { setAccountModalVisibility } from 'services/accountmodal/accountmodal.actions';
import { userLogin } from 'services/auth/auth.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './main.less';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faTwitter, faYoutube, faInstagram, faLinkedin);


//TODO: UPDATE AUTH USER THROUGH THE STOOOOORRRRRREEEEEEEE
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
    this.props.setFirebase(firebase);
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== null && prevProps.user === null) {

      this.setState({
        isLoading: false
      });
    }
  }

  componentWillReceiveProps(newProps) {
    this.listener = newProps.firebase.auth.onAuthStateChanged(
      (authUser) => {
         if (authUser && authUser.emailVerified) {
           firebase.setIsAuthenticated(true)
           if (this.props.user === null) {
             this.props.getUser();
           }

         } else {
           this.setState({
             isLoading: false
           });
         }
      },
    );
  }

  render() {
    if (this.props.loggedOut && !this.state.isLoading) {
      this.setState({
        isLoading: true
      }, ()=>{
        window.location.href = Routes.LANDING;
      });
    }

    let {
      loginSuccess,
      loginError,
      loginErrorMessage,
      error,
      errorMessage,
      success,
      token,
      email,
      setAccountModalVisibility,
      accountModalVisibility,
      createUser,
      userLogin,
      resend,
      resendsuccess
    } = this.props;

    return (
      (this.state.isLoading ?
          <Loader />
        :

        <div className="app-container d-flex flex-column">
          <Router>
            <ScrollToTop>

              <Switch>
                <Route exact path="/org/:path" component={CompaniesRoutes} />
                <>
                <Route component={NavBar} />
                <Route exact path="/org/hoy" component={HOY} />
                <Route exact path={"/sandbox"} component={Sandbox} />
                <Route exact path={Routes.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route exact path={Routes.LANDING} component={Home} />
                <Route exact path={Routes.LEARN_MORE_SPONSOR} component={Sponsor} />
                <Route exact path={Routes.LEARN_MORE_EVENT_ORGANIZER} component={EventOrganizer} />
                <Route exact path={Routes.LEARN_MORE_RESOURCES} component={Resources} />
                <Route exact path={Routes.SIGN_UP} component={SignUp} />
                <Route exact path={Routes.ONBOARDING_GET_STARTED} component={GetStarted} />
                <Route exact path={Routes.ONBOARDING_CREATE_ACCOUNT} component={CreateAccount} />
                <Route exact path={Routes.ORGANIZER_ONBOARDING_INTRO} component={Intro} />
                <Route exact path={Routes.LOG_IN} component={LogIn} />
                <Route exact path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route exact path={Routes.VERIFY} component={Verify} />
                <Route exact path={Routes.RESEND_VERIFICATION} component={Resend} />
                <Route exact path={Routes.BLOG_POST} component={Post} />
                <Route exact path={Routes.BLOG} component={Blog} />
                <Route exact path={Routes.SETTINGS} component={SettingsModule} />
                <Route exact path={Routes.CHAT_ROOM} component={Negotiations} />
                <ProtectedRoute exact path={Routes.EVENTS_SEARCH} component={Search} />
                <Route exact path={Routes.EVENT} component={Event} />
                {/*
                  <ProtectedRoute exact path={Routes.EVENT} component={Event} />
                */}
                <Route exact path={Routes.EXPERIENCE_THIS} component={ExperienceThisRoutes} />



                <Route exact path={Routes.EVENTS_CREATE} component={CreateEventRoutes} />
                <Route exact path={Routes.EVENT_DASHBOARD} component={EventDashboardRoutes} />
                <Route exact path={Routes.PORTFOLIO_CREATE} component={CreatePortfolioRoutes} />

                <ProtectedRoute path={Routes.SPONSOR_HOME} component={SponsorHome} />
                <ProtectedRoute path={Routes.ORGANIZER_HOME} component={EventsHome} />
                <Route exact path={"/portfolio/:id"} children={({match})=>{
                  if (match === null) {
                    return null;
                  }

                  if (match.isExact) {
                    return (<ProtectedRoute path={Routes.PORTFOLIO} component={Portfolio} />)
                  }
                  return null;
                }} />

                {/*In progress*/}
                <ProtectedRoute path={Routes.UPLOAD} component={AssetUpload} />

                {/*Junk below*/}
                <ProtectedRoute path={Routes.EVENT_VIEW+"/old"} component={OldEvent} />
                <ProtectedRoute path={Routes.TEST} component={Test} />
                <ProtectedRoute path={Routes.PDF} component={PDFs} />

                <ProtectedRoute allowedRoles={[UserTypes.ADMIN.id]} path={Routes.EVENT_SPONSORS} component={EventSponsors} />
                <ProtectedRoute allowedRoles={[UserTypes.ADMIN.id]} path={Routes.EVENT_RANKINGS} component={EventRankings} />
                <ProtectedRoute allowedRoles={[UserTypes.ADMIN.id]} path={Routes.EVENT_RANKINGS_DIVISION} component={EventRankingsDivision} />
                <ProtectedRoute allowedRoles={[UserTypes.ADMIN.id]} path={Routes.SPONSOR_RANKINGS} component={SponsorRankings} />
                <ProtectedRoute allowedRoles={[UserTypes.ADMIN.id]} path={Routes.ADMIN_EVENT_REVIEW} component={AdminEventReview} />
                <ProtectedRoute allowedRoles={[UserTypes.ADMIN.id]} path={Routes.ADMIN_EVENTS} component={AdminEvents} />
                <Route path="*" component={NotFound} />
                </>
              </Switch>
            </ScrollToTop>
          </Router>
          <CreateAccountModal
            loginSuccess={loginSuccess}
            loginError={loginError}
            loginErrorMessage={loginErrorMessage}
            error={error}
            errorMessage={errorMessage}
            success={success}
            token={token}
            email={email}
            resend={resend}
            resendsuccess={resendsuccess}
            close={setAccountModalVisibility.bind(this, false)}
            visible={accountModalVisibility}
            userLogin={userLogin.bind(this)}
            onSubmit={createUser.bind(this)}/>
        </div>

      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebase: state.firebase.firebase,
    user: state.users.currentUser,
    loggedOut: state.auth.loggedOut,
    accountModalVisibility: state.accountmodal.visibility,
    errorMessage: state.signUp.errorMessage,
    error: state.signUp.error,
    success: state.signUp.success,
    token: state.signUp.token,
    email: state.signUp.email,
    resendsuccess: state.verify.resendsuccess,
    resentToken: state.verify.resentToken,
    loginErrorMessage: state.auth.errorMessage,
    loginError: state.auth.error,
    loginSuccess: state.auth.success
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        setFirebase,
        setCurrentUser,
        getUser,
        createUser,
        userLogin,
        setAccountModalVisibility,
        resend
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
