import React, { Component } from 'react';
import Hero from 'screens/sandbox/hero/hero';
import EventCard from 'screens/sandbox/components/eventcard';
import { PieChart } from 'screens/sandbox/components/piechart';
import HOYLogo from 'assets/hoy-logo.png';
import AudienceInfoComponents from 'screens/sandbox/components/audienceinfocomponents';
import Packages from 'screens/sandbox/components/packages';
import BulletedPackages from 'screens/sandbox/components/bulletedpackages';
import Footer from 'screens/learnmore/components/footer.component';
import Button from 'components/new/Button';
import List from 'common/list.component';
import {
  Routes
} from 'constants/Routes';
import 'screens/sandbox/sandbox.css';
import './hoy.css';

const HOY_WEBSITE = "https://houseofyes.org/"
const HOY_TWITTER = "https://twitter.com/houseofyesnyc"
const HOY_FB = "https://www.facebook.com/houseofyes/"
const HOY_INSTAGRAM = "https://www.instagram.com/houseofyesnyc/"
const HOY_YOUTUBE = "https://www.youtube.com/channel/UCyyXLDaNpfcQtVsaLdhO82g"
const HOY_EMAIL = "amber@restagex.com"

const EARTH_LOVE_PDF = "https://s3.amazonaws.com/assets.restagex.com/org/resources/EARTH+LOVE+2020+-+HOY.pdf"
const GOLDEN_ERA_PDF = "https://s3.amazonaws.com/assets.restagex.com/org/resources/Golden+Era+x+House+of+Yes.pdf"

const age_data = [
  {
    value: 12,
    label: "21-25"
  },
  {
    value: 10,
    label: "40+"
  },
  {
    value: 18,
    label: "35-40"
  },
  {
    value: 60,
    label: "25-34"
  }
];

const gender_data = [
  {
    value: 55,
    //label: "F"
    label: "Female Identifying"
  },
  {
    value: 35,
    //label: "F"
    label: "Male Identifying"
  },
  {
    value: 10,
    //label: "F"
    label: "Other"
  },
];

const items = [
  () => (
    <div style={{paddingTop: '15px'}}>
      <div className="restage-montserrat restage-font-bold restage-font-16 restage-font-brand-secondary">Age</div>
      <PieChart data={age_data}/>
    </div>
  ),
  () => (
    <div style={{paddingTop: '15px'}}>
      <div className="restage-montserrat restage-font-bold restage-font-16 restage-font-brand-secondary">Gender</div>
      <PieChart data={gender_data}/>
    </div>
  ),
  /*() => (
    <div style={{paddingTop: '15px'}}>
      <List items=
    </div>
  )*/
]

const TRADITIONAL_PACKAGES = [
  {
    "name": "Platinum",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  },
  {
    "name": "Gold",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  },
  {
    "name": "Silver",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  }
]

const SPECIALTY_PACKAGES = [
  {
    "name": "Wifi",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  }
]

const PACKAGES = [
  {
    "id": 1,
    "name": "Tier 1",
    "price": "$5,000",
    "cost": 5000,
    "items": [
      {
        "id": 1,
        "description": "Custom activation based on your brand",
        "placement": {
          "placement": "Custom activation based on your brand",
        }
      },
      {
        "id": 2,
        "description": "2 - 3 Branded recap photos",
        "placement": {
          "placement": "2 - 3 Branded recap photos",
        }
      },
      {
        "id": 3,
        "description": "Event page (Facebook & Eventbrite) social tags",
        "placement": {
          "placement": "Event page (Facebook & Eventbrite) social tags",
        }
      },
      {
        "id": 4,
        "description": "Inclusion in social posts with all $5K level sponsors",
        "placement": {
          "placement": "Inclusion in social posts with all $5K level sponsors",
        }
      },
      /*{
        "description": "Inclusion in 2 mass emails to rsvp's"
      },*/
      {
        "id": 5,
        "description": <div>Inclusion in 2 mass emails to rsvp's<div style={{paddingTop: '10px'}}><List type="hollow" expand={true} justification="vertical" items={["Pre event email", "Thank you post event email"]} /></div></div>,
        "placement": {
          "placement": <div>Inclusion in 2 mass emails to rsvp's<div style={{paddingTop: '10px'}}><List type="hollow" expand={true} justification="vertical" items={["Pre event email", "Thank you post event email"]} /></div></div>,
        }
      },
    ]
  },
  {
    "id": 2,
    "name": "Tier 2",
    "price": "$10,000",
    "cost": 10000,
    "items": [
      {
        "id": 1,
        "description": "Custom show segment or activation based on your brand",
        "placement": {
          "placement": "Custom show segment or activation based on your brand",
        }
      },
      {
        "id": 2,
        "description": "4 - 6 Branded recap photos",
        "placement": {
          "placement": "4 - 6 Branded recap photos",
        }
      },
      {
        "id": 3,
        "description": "15 second branded video recap",
        "placement": {
          "placement": "15 second branded video recap",
        }
      },
      {
        "id": 4,
        "description": "Event page (Facebook & Eventbrite) social tags",
        "placement": {
          "placement": "Event page (Facebook & Eventbrite) social tags",
        }
      },
      {
        "id": 5,
        "description": "Inclusion in social post with all $10K level sponsors",
        "placement": {
          "placement": "Inclusion in social post with all $10K level sponsors",
        }
      },
      {
        "description": "Inclusion in pre-event promotional newsfeed post",
        "placement": {
          "placement": "Inclusion in pre-event promotional newsfeed post",
        }
      }
      ,
      /*{
        "description": "Inclusion in 2 mass emails to rsvp's"
      },*/
      {
        "id": 6,
        "description": <div>Inclusion in 2 mass emails to rsvp's<div style={{paddingTop: '15px'}}><List type="hollow" expand={true} justification="vertical" items={["Pre event email", "Thank you post event email"]} /></div></div>,
        "placement": {
          "placement": <div>Inclusion in 2 mass emails to rsvp's<div style={{paddingTop: '15px'}}><List type="hollow" expand={true} justification="vertical" items={["Pre event email", "Thank you post event email"]} /></div></div>,
        }
      },
    ]
  },
  {
    "id": 3,
    "name": "Tier 3",
    "price": "$20,000",
    "cost": 20000,
    "items": [
      {
        "id": 1,
        "description": "Room takeover/title sponsor",
        "placement": {
          "placement": "Room takeover/title sponsor",
        }
      },
      {
        "id": 2,
        "description": "Custom show segment or activation",
        "placement": {
          "placement": "Custom show segment or activation",
        }
      },
      {
        "id": 3,
        "description": "7 - 10 branded recap photos",
        "placement": {
          "placement": "7 - 10 branded recap photos",
        }
      },
      {
        "id": 4,
        "description": "30 second branded video recap",
        "placement": {
          "placement": "30 second branded video recap",
        }
      },
      /*{
        "description": "1 - 2 dedicated social posts"
      },*/
      {
        "id": 5,
        "description": <div>1 - 2 dedicated social posts<div style={{paddingTop: '15px'}}><List type="hollow" expand={true} justification="vertical" items={["Event page wall(s)", "IG highlight"]} /></div></div>,
        "placement": {
          "placement": <div>1 - 2 dedicated social posts<div style={{paddingTop: '15px'}}><List type="hollow" expand={true} justification="vertical" items={["Event page wall(s)", "IG highlight"]} /></div></div>,
        }
      },
      {
        "id": 6,
        "description": "Event page (Facebook & Eventbrite social tags)",
        "placement": {
          "placement": "Event page (Facebook & Eventbrite social tags)",
        }
      },
      {
        "id": 7,
        "description": "Inclusion in pre-event promotional newsfeed post",
        "placement": {
          "placement": "Inclusion in pre-event promotional newsfeed post",
        }
      },
      /*{
        "description": "Top billing name inclusion in 2 mass emails to rsvp's"
      },*/
      {
        "id": 8,
        "description": <div>Top billing name inclusion in 2 mass emails to rsvp's<div style={{paddingTop: '15px'}}><List type="hollow" expand={true} justification="vertical" items={["Pre event email", "Thank you post event email"]} /></div></div>,
        "placement": {
          "placement": <div>Top billing name inclusion in 2 mass emails to rsvp's<div style={{paddingTop: '15px'}}><List type="hollow" expand={true} justification="vertical" items={["Pre event email", "Thank you post event email"]} /></div></div>,
        }
      },
    ]
  }
]

class HOY extends Component {
  openCalendly() {
    window.open("https://www.calendly.com/kiamber", "_blank",)
  }

  goToCreateAnEvent() {
    this.props.history.push(Routes.ORGANIZER_ONBOARDING_INTRO)
  }

  render() {
    const organization = {
      name: "House of Yes",
      website: "https://houseofyes.org"

    }
    return (
      <div>
        <Hero />
        <div className="d-flex flex-md-row flex-column restage-event-eventinfo-container justify-content-between">
          <div>
            <EventCard
              event={{name: "Epic Halloween Costume Sale", date: "Oct 24, 2020", time: "2:00 PM EST", ticket_url: "https://eventbrite.com/e/epic-halloween-costume-sale-tickets-126183132145", website: "https://restagex.com", logo: HOYLogo, email: HOY_EMAIL, website: HOY_WEBSITE, twitter: HOY_TWITTER, facebook: HOY_FB, instagram: HOY_INSTAGRAM, youtube: HOY_YOUTUBE}}
              organization={organization}
            />
            <div className="restage-event-documents d-flex flex-column">
              <div className="restage-montserrat restage-font-bold restage-font-30 restage-font-brand-secondary">Documents</div>
              <a className="restage-link body-copy-tertiary-link" href={GOLDEN_ERA_PDF} target="_blank">Golden Era & House of Yes</a>
              <a className="restage-link body-copy-tertiary-link" href={EARTH_LOVE_PDF} target="_blank">Earth Love 2020</a>
            </div>
          </div>
          <div className="restage-packages-container">
            <div className="restage-font-333333 restage-poppins restage-font-48" style={{paddingLeft: '2.083vw'}}>Sponsorships Menu</div>
            {/*}<Packages packages={TRADITIONAL_PACKAGES} label="Traditional Packages" className="restage-packages-accordion" />*/}
            {/*}<Packages packages={SPECIALTY_PACKAGES} label="Specialty Packages" className="restage-packages-accordion" />*/}
            <BulletedPackages packages={PACKAGES} label="Packages" className="restage-packages-accordion" />
          </div>
          <AudienceInfoComponents items={items} />
        </div>
        <Footer background="purple">
          <div className="d-flex flex-sm-row resources-ctas flex-column">
            <div className="d-flex align-items-center resources-create-cta"><Button label="CREATE EVENT" size="sm" type="primary" onClick={this.goToCreateAnEvent.bind(this)}  /></div>
            <div className="d-flex align-items-center"><Button style={{marginRight: '15px'}} label="BOOK A DEMO" size="sm" type="tertiary" onClick={this.openCalendly.bind(this)}  /></div>
          </div>
        </Footer>
      </div>
    )
  }
}

export default HOY;
