import React, { Component } from 'react';
import ObjectivesGroup from './components/objectivesgroup.component';
import ButtonGroup from 'components/ButtonGroup';
import Detail from 'common/Detail';
import { isFilled } from 'common/helpers/validators';
import { postPortfolio, setPortfolioObjectivePage, setPortfolioName } from 'services/portfolios/portfolios.actions';
import { postPortfolioObjective, setPortfolioObjective } from 'services/portfolioobjectives/portfolioobjectives.actions';
import { Routes } from 'constants/Routes';
import TextField from 'components/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './objectives.css';

//To-do: Move to common place or read from db
const BRAND_OBJECTIVES = {
  BRAND_AWARENESS: "Brand awareness",
  REACH: "Reach",
  TRAFFIC: "Traffic",
  ENGAGEMENT: "Engagement",
  APP_INSTALLS: "App installs",
  VIDEO_VIEWS: "Video views",
  LEAD_GENERATION: "Lead generation",
  CONVERSIONS: "Conversions",
  STORE_TRAFFIC: "Store traffic"
}

const {
  BRAND_AWARENESS,
  REACH,
  TRAFFIC,
  ENGAGEMENT,
  APP_INSTALLS,
  VIDEO_VIEWS,
  LEAD_GENERATION,
  CONVERSIONS,
  STORE_TRAFFIC
} = BRAND_OBJECTIVES;

const objectives = [
  BRAND_AWARENESS,
  REACH,
  TRAFFIC,
  ENGAGEMENT,
  APP_INSTALLS,
  VIDEO_VIEWS,
  LEAD_GENERATION,
  CONVERSIONS,
  STORE_TRAFFIC
];

class Objectives extends Component {
  static defaultProps = {
    events: []
  }

  constructor(props) {
    super(props);
    let setObjectives = {}

    objectives.map((item) => {
      setObjectives[item] = {
        objective: item,
        active: false
      }
    })

    this.state = {
      name: {
        value: "",
        error: false
      },
      selected: props.objective,
      error: false,
      ...setObjectives
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let toggleState = false;
    let objectives = {};

    for (let key in this.state) {
      let { objective, active } = this.state[key];

      let newActive = false;

      if (active != null) {
        if (key === this.props.objective && !active) {
          active = true;
          toggleState = true;
        }
        objectives[key] = {
          objective,
          active
        }
      }
    }

    if (toggleState) {
      this.setState({
        ...objectives
      });
    }
  }

  isFieldValid(validator, key) {
    let isValid = true;

    isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }

  validate() {
    let fields = new Set();
    fields.add(this.isFieldValid(isFilled, "name"));
    return this.state.selected && !fields.has(false);;
  }

  goToSponsorHome() {
    this.props.history.push(Routes.SPONSOR_HOME);
  }

  goToPortfolioAudienceProfile() {
    let isValid = this.validate();
    let { setPortfolioObjectivePage, history, postPortfolio, postPortfolioObjective, portfolio, setPortfolioObjective, setPortfolioName} = this.props;

    this.setState({
      error: !isValid
    });

    if (!isValid) {
      return;
    }

    let { selected } = this.state;

    if (portfolio) {
      postPortfolioObjective(
        selected,
        portfolio.id,
        this.state.name.value
      )
    } else if (portfolio === null) {
      postPortfolio({
        name: this.state.name.value,
        objective: selected
      });
    }
    setPortfolioName(this.state.name.value)
    setPortfolioObjective(selected);
    setPortfolioObjectivePage(true);
    history.push(Routes.PORTFOLIO_AUDIENCE);
  }

  onClickToggleObjective(objective) {
    let { selected } = this.state;
    this.props.setPortfolioObjective(objective);
    if (selected) {
      this.setState({
        ...this.state,
        selected: objective,
        [selected]: {
          objective: selected,
          active: false
        },
        [objective]: {
          objective: objective,
          active: true
        }
      })
    } else {
      this.setState({
        ...this.state,
        selected: objective,
        [objective]: {
          objective: objective,
          active: true
        }
      })
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  postPortfolioObjectives() {
    let isValid = this.validate();
  }

  render() {
    let { objectives, name, budget, startdate, enddate, duedate } = this.state;
    let { awareness, consideration, conversion } = this.state;

    return (
      <div>
        <div>
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}}
            header="What’s your sponsorship objective?"
            subHeader="Tell us about your goals so that we can customize your experience. Please select one objective per portfolio." />
          <div style={{marginBottom: '25px'}}>
            <TextField

              fontStyle="tertiary"
              name="name"
              placeholder="Campaign name"
              value={(name.value == "" && this.props.portfolio_name ? this.props.portfolio_name : name.value)}
              errorMessage="Campaign name cannot be empty."
              error={name.error}
              onChange={this.onChange.bind(this)}
              label="Campaign Name"
              size="lg" />
          </div>
          <div style={{marginBottom: '50px'}} className="portfolio-objectives-container d-flex flex-sm-row flex-column">
            <ObjectivesGroup
              onClick={this.onClickToggleObjective.bind(this)}
              header="Awareness"
              objectives={[this.state[BRAND_AWARENESS], this.state[REACH]]}
              className="col-sm-4 col-12 objectivesgroup-container"
            />
            <ObjectivesGroup
              onClick={this.onClickToggleObjective.bind(this)}
              header="Consideration"
              objectives={[this.state[TRAFFIC], this.state[ENGAGEMENT], this.state[APP_INSTALLS], this.state[VIDEO_VIEWS], this.state[LEAD_GENERATION]]}
              className="col-sm-4 col-12 objectivesgroup-container"
            />
            <ObjectivesGroup
              onClick={this.onClickToggleObjective.bind(this)}
              header="Conversion"
              objectives={[this.state[CONVERSIONS], this.state[STORE_TRAFFIC]]}
              className="col-sm-4 col-12 objectivesgroup-container"
            />
          </div>
        </div>
        {this.state.error ? <div style={{alignSelf: 'stretch', position: "relative"}}><span className="body-copy-error-xs-small" style={{textAlign: 'center', position: "absolute", color: 'red'}}>Please select one objective.</span></div> : null}
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 1 of 4</div>
          <ButtonGroup
            labelPrimary="Next"
            labelSecondary="Back"
            onClickPrimary={this.goToPortfolioAudienceProfile.bind(this)}
            onClickSecondary={this.goToSponsorHome.bind(this)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    portfolio: state.portfolios.currentPortfolio,
    objective: state.portfolioobjectives.objective,
    portfolio_name: state.portfolios.portfolio_name
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      postPortfolio,
      setPortfolioObjectivePage,
      postPortfolioObjective,
      setPortfolioObjective,
      setPortfolioName
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Objectives);
