import React, { Component } from 'react';
import './dot.css';

export default class Dot extends Component {
  getClassName() {
    let classNames =  ["restage-dot"]
    let { selected } = this.props;

    if (selected) classNames.push("restage-background-coral")
    else classNames.push("restage-home-hero-dot-inactive");
    return classNames.join(" ");
  }

  render() {
    let { onClick } = this.props;
    return (
      <button
        className={this.getClassName()}
        onClick={onClick.bind(this)} />
    )
  }
}
