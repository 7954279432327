import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, withRouter  } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PortfolioSponsorSet from '../screens/sponsor/create/sponsorset/portfoliosponsorset.screen';
import Objectives from '../screens/sponsor/create/objectives/objectives.screen';
import Preferences from '../screens/sponsor/create/preferences/preferences.screen';
import PortfolioAudience from '../screens/sponsor/create/audience/portfolioaudience.screen';
import PortfolioPlacements from '../screens/sponsor/create/placements/portfolioplacements.screen';
import PortfolioReview from '../screens/sponsor/create/review/portfolioreview.screen';
import CreatePortfolio from '../screens/sponsor/create/createportfolio.screen';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { Routes } from 'constants/Routes';

const routes = [
  { path: Routes.PORTFOLIO_OBJECTIVES, screen: Objectives },
  { path: Routes.PORTFOLIO_AUDIENCE, screen: PortfolioAudience },
  { path: Routes.PORTFOLIO_PREFERENCES, screen: Preferences },
  { path: Routes.PORTFOLIO_REVIEW, screen: PortfolioReview }
]

class CreatePortfolioRoutes extends Component {
  render() {
    return (
      <CreatePortfolio history={this.props.history}>
        <TransitionGroup>
          <CSSTransition
            key={this.props.history.location.pathname}
            timeout={600}
            classNames='restage-route-transition'>
            <Switch>
              {
                routes.map(({ path, screen }) => {
                  return (
                    <ProtectedRoute key={path} exact path={path} component={screen}/>
                  )
                })
              }
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </CreatePortfolio>
    )
  }
}

export default withRouter(CreatePortfolioRoutes);
