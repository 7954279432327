import React, { Component } from 'react';
import HOY from 'screens/company/hoy.screen';
import { Route, Switch } from "react-router-dom";
import { Routes } from 'constants/Routes';

const routes = [
  { path: "/org/house-of-yes", screen: HOY }
]

class CompaniesRoutes extends Component {
  componentDidMount() {
    console.log("rahh")
  }

  render() {
    return (
      <Switch>
        {
          routes.map(({ path, screen }) => {
            console.log("path", path)
            return (
              <Route key={path} exact path={path} component={screen}/>
            )
          })
        }
      </Switch>
    )
  }
}

export default CompaniesRoutes;
