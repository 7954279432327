import React, { Component } from 'react';
import Dot from './dot';
import './dots.css';

export default class Dots extends Component {
  static defaultProps = {
    onChange: () => {},
    className: ""
  }

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeIndex !== this.props.activeIndex) {
      this.setState({
        activeIndex: this.props.activeIndex
      })
    }
  }

  onClickDot(index) {
    let { onChange } = this.props;
    this.setState({
      activeIndex: index
    }, () => {
      onChange(index);
    })
  }

  render() {
    let { items, onClick, className } = this.props;
    let { activeIndex } = this.state;
    return (
      <div className={`d-flex js-dots ${className}`}>
        {
          items.map((item, index) => {
            console.log(activeIndex, index)
            return (
              <Dot
                onClick={this.onClickDot.bind(this, index)}
                selected={activeIndex == index}
              />
            )
          })
        }
      </div>
    )
  }
}
