import React, { Component } from 'react';
import Hero from './hero/hero';
import EventCard from './components/eventcard';
import HOYLogo from 'assets/hoy-logo.png';
import AudienceInfo from './components/audienceinfo';
import Packages from './components/packages';
import Footer from 'screens/learnmore/components/footer.component';
import Button from 'components/new/Button';
import {
  Routes
} from 'constants/Routes';
import './sandbox.css';

const HOY_WEBSITE = "https://houseofyes.org/"
const HOY_TWITTER = "https://twitter.com/houseofyesnyc"
const HOY_FB = "https://www.facebook.com/houseofyes/"
const HOY_INSTAGRAM = "https://www.instagram.com/houseofyesnyc/"
const HOY_YOUTUBE = "https://www.youtube.com/channel/UCyyXLDaNpfcQtVsaLdhO82g"
const HOY_EMAIL = "amber@restagex.com"

const TRADITIONAL_PACKAGES = [
  {
    "name": "Platinum",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  },
  {
    "name": "Gold",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  },
  {
    "name": "Silver",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  }
]

const SPECIALTY_PACKAGES = [
  {
    "name": "Wifi",
    "price": "$00.00",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "contents": "Digital, Physical, Custom < Needs to be more descriptive on the form",
    "limit": 10
  }
]

class Sandbox extends Component {
  openCalendly() {
    window.open("https://www.calendly.com/kiamber", "_blank",)
  }

  goToCreateAnEvent() {
    this.props.history.push(Routes.ORGANIZER_ONBOARDING_INTRO)
  }

  render() {
    const organization = {
      name: "House of Yes",
      website: "https://houseofyes.org"

    }
    return (
      <div>
        <Hero />
        <div className="d-flex flex-md-row flex-column restage-event-eventinfo-container justify-content-between">
          <EventCard
            event={{name: "Event Name", date: "Date", time: "Time", ticket_url: "https://www.eventbrite.com/e/heels-heels-heels-tickets-123942303767", website: "https://restagex.com", logo: HOYLogo, email: HOY_EMAIL, website: HOY_WEBSITE, twitter: HOY_TWITTER, facebook: HOY_FB, instagram: HOY_INSTAGRAM, youtube: HOY_YOUTUBE}}
            organization={organization}
          />
          <div className="restage-packages-container">
            <Packages packages={TRADITIONAL_PACKAGES} label="Traditional Packages" className="restage-packages-accordion" />
            <Packages packages={SPECIALTY_PACKAGES} label="Specialty Packages" className="restage-packages-accordion" />
            {/*}<Packages label="" className="restage-packages-accordion" />*/}
          </div>
          <AudienceInfo />
        </div>
        <Footer background="purple">
          <div className="d-flex flex-sm-row resources-ctas flex-column">
            <div className="d-flex align-items-center resources-create-cta"><Button label="CREATE EVENT" size="sm" type="primary" onClick={this.goToCreateAnEvent.bind(this)}  /></div>
            <div className="d-flex align-items-center"><Button style={{marginRight: '15px'}} label="BOOK A DEMO" size="sm" type="tertiary" onClick={this.openCalendly.bind(this)}  /></div>
          </div>
        </Footer>
      </div>
    )
  }
}

export default Sandbox;
