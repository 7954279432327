import React, { Component } from 'react';
import DotNav from "./dotNav"
import PlaylistButton from "./playlistButton"
import PlaylistMenu from "./playlistMenu";
import "./playlistNav.css"

export default class PlaylistNav extends React.Component {
  render() {
    let {
      onChangeVideo,
      onChangePlaylist,
      selectedPlaylist,
      currentlyPlayingVideoId,
      playlists
    } = this.props;
    let { videos } = selectedPlaylist;

    let currentVideo = videos[currentlyPlayingVideoId]
    return(
      <div className="d-flex expt-playlistNav-container justify-content-between align-items-center">
        <PlaylistButton
          videoTitle={currentVideo.title}
          videoDuration={currentVideo.durationPretty}
          playlists={playlists}
          selectedPlaylist={selectedPlaylist}
          onChangePlaylist={onChangePlaylist.bind(this)}
        />
        <DotNav
          currentlyPlayingVideoId={currentlyPlayingVideoId}
          dotsData={videos}
          onChangeVideo={onChangeVideo.bind(this)}
          onChangePlaylist={onChangePlaylist.bind(this)}
        />
      </div>
    )
  }
}
