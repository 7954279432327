import React, { Component } from 'react';
import { Switch, withRouter, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import CreateEvent from '../screens/event/create/createevent.screen';
import RequestSponsorship from '../screens/event/create/sponsorship/requestsponsorship.screen';
import EventDetails from '../screens/event/create/details/eventdetails.screen';
import EventAudience from '../screens/event/create/audience/eventaudience.screen';
import EventPlacements from '../screens/event/create/placements/eventplacements.screen';
import EventPackages from '../screens/event/create/packages/eventpackages.screen';
import EventReview from '../screens/event/create/review/eventreview.screen';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { getSuggestions, setSuggestions } from 'services/places/places.actions';
import { getEvent, putEvent, postEvent, setEventDetailsPage, publish } from 'services/events/events.actions';
import { postEventAssets, addEventAsset } from 'services/eventassets/eventassets.actions';
import { postEventAudienceProfile, putEventAudienceProfile, setEventAudiencePage } from 'services/eventaudienceprofile/eventaudienceprofile.actions';
import { searchPlacements, setPlacements } from 'services/placements/placements.actions';
import { postPackages, putPackages, addPackage, removePackage, setEventPackagesPage } from 'services/packages/packages.actions';
import { isNumeric } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const {
  EVENT_DETAILS,
  EVENT_PACKAGES,
  EVENT_PLACEMENTS,
  EVENT_AUDIENCE,
  EVENT_REVIEW
} = Routes;

const CREATE_EVENT_URLS = [EVENT_DETAILS, EVENT_AUDIENCE, EVENT_PACKAGES, EVENT_REVIEW];

const routes = [
  { path: EVENT_DETAILS, Component: EventDetails },
  { path: EVENT_PACKAGES, Component: EventPackages },
  { path: EVENT_PLACEMENTS, Component: EventPlacements },
  { path: EVENT_AUDIENCE, Component: EventAudience },
  { path: EVENT_REVIEW, Component: EventReview }
]

class CreateEventRoutes extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;

    if (id !== undefined && !isNumeric(id)) {
      this.props.getEvent(id)
      return;
    }
  }

  render() {
    let {
      isEventPackagesPageValid,
      isEventDetailsPageValid,
      isEventAudiencePageValid,

      suggestions,
      getSuggestions,
      setSuggestions,

      assets,
      attributes,
      event,
      user,
      addEventAsset,
      postEvent,
      putEvent,
      postEventAssets,
      setEventDetailsPage,

      postEventAudienceProfile,
      putEventAudienceProfile,
      event_audience_profile,
      name,
      setEventAudiencePage,

      available,
      placements,
      packages,
      savedPackages,
      searchPlacements,
      setPlacements,
      postPackages,
      putPackages,
      addPackage,
      removePackage,
      setEventPackagesPage,

      publish
    } = this.props;

    const { pathname } = this.props.history.location;

    if (!CREATE_EVENT_URLS.includes(pathname)) return <Redirect to={EVENT_DETAILS} />;
    return (
      <CreateEvent
        isEventPackagesPageValid={isEventPackagesPageValid}
        isEventDetailsPageValid={isEventDetailsPageValid}
        isEventAudiencePageValid={isEventAudiencePageValid}
        event={this.props.event}
        history={this.props.history}>
        <TransitionGroup>
          <CSSTransition
            key={this.props.history.location.pathname}
            timeout={600}
            classNames='restage-route-transition'>
            <Switch>
              <ProtectedRoute
                event={event}
                assets={assets}
                user={user}
                postEvent={postEvent.bind(this)}
                putEvent={putEvent.bind(this)}
                postEventAssets={postEventAssets.bind(this)}
                addEventAsset={addEventAsset.bind(this)}
                setEventDetailsPage={setEventDetailsPage.bind(this)}
                key={EVENT_DETAILS} path={EVENT_DETAILS} component={EventDetails}/>
              <ProtectedRoute
                isEventDetailsPageValid={isEventDetailsPageValid}
                event={event}
                attributes={attributes}
                name={name}
                event_audience_profile={event_audience_profile}
                putEventAudienceProfile={putEventAudienceProfile.bind(this)}
                postEventAudienceProfile={postEventAudienceProfile.bind(this)}
                setEventAudiencePage={setEventAudiencePage.bind(this)}
                key={EVENT_AUDIENCE} path={EVENT_AUDIENCE} component={EventAudience}/>
              <ProtectedRoute
                savedPackages={savedPackages}
                isEventDetailsPageValid={isEventDetailsPageValid}
                isEventAudiencePageValid={isEventAudiencePageValid}
                searchPlacements={searchPlacements.bind(this)}
                setPlacements={setPlacements.bind(this)}
                postPackages={postPackages.bind(this)}
                putPackages={putPackages.bind(this)}
                addPackage={addPackage.bind(this)}
                removePackage={removePackage.bind(this)}
                available={available}
                placements={placements}
                packages={packages}
                event={event}
                setEventPackagesPage={setEventPackagesPage.bind(this)}
                key={EVENT_PACKAGES} exact path={EVENT_PACKAGES} component={EventPackages}/>
              <ProtectedRoute
                isEventDetailsPageValid={isEventDetailsPageValid}
                isEventAudiencePageValid={isEventAudiencePageValid}
                isEventPackagesPageValid={isEventPackagesPageValid}
                packages={packages}
                attributes={attributes}
                event={event}
                publish={publish.bind(this)}
                key={EVENT_REVIEW} exact path={EVENT_REVIEW} component={EventReview}/>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </CreateEvent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    suggestions: state.places.suggestions,
    event_audience_profile: state.eventaudienceprofile.event_audience_profile,
    assets: state.eventassets.assets,
    attributes: state.audienceprofile.attributes,
    event: state.events.currentEvent,
    name: state.audienceprofile.name,
    user: state.users.currentUser,
    available: state.availableplacements.available,
    placements: state.placements.placements,
    packages: state.packages.packages,
    savedPackages: state.packages.savedPackages,
    isEventPackagesPageValid: state.packages.isEventPackagesPageValid,
    isEventDetailsPageValid: state.events.isEventDetailsPageValid,
    isEventAudiencePageValid: state.eventaudienceprofile.isEventAudiencePageValid
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getSuggestions,
      setSuggestions,
      addEventAsset,
      getEvent,
      putEvent,
      postEvent,
      postEventAssets,
      postEventAudienceProfile,
      putEventAudienceProfile,
      publish,
      setEventAudiencePage,
      setEventDetailsPage,
      searchPlacements,
      setPlacements,
      postPackages,
      putPackages,
      addPackage,
      removePackage,
      setEventPackagesPage
    }, dispatcher)
);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateEventRoutes));
